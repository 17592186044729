import { AsamDimensionsModel, AssesmentHeaderModel, ChiefComplaintModel, ClientSaftyPlanModel, CurrentImpairmentssModel, CurrentMedicationsModel, CurrentServicesModel, EmploymentVocationalModel, GAD7AnxietyModel, GeneralInforModel, HealthNotesModel, HistoryofAbuseModel, HistoryofPsychiatricDiagnosesModel, HistoryofSymptomsModel, HouseHoldMemberModel, IndependentLivingModel, LegalHistoryModel, LeisureActivityModel, MedicalHistoryModel, MentalStatusExamModel, PSAServiceHistoryModel, RecoveryAssesmentScaleModel, RecoveryHistoryandEnvironmentModel, ReligiousCulturalModel, SubstanceInfoModel, SuicideRiskPotentialModel, SummaryofNeedSchemaModel, SummeryOfFindingModel, mortivationEngageInServices, psychiatricSymptomModel, PatientHealthQuestionaireModel, SignatureDetailsModel, AceScoreModel } from "./clinicalAssesmentSubmodel";

export enum currentLivingSituation {
  RENTING = "RENTING",
  OWN = "OWN",
  HOUSE = "HOUSE",
  APT = "APT",
  MOBILEHOME = "MOBILE_HOME"
}

export enum CAFormType {
  OLD_CA = "OLD_CA",
  NEW_CA = "NEW_CA"
}

export interface ClinicalAssetment {
  stepCount?: number;
  _id?: string;
  clientId?: string;
  isSignature?: boolean;
  assesmentHeader?: AssesmentHeaderModel;
  lengthOfAssestment?: string;
  dateOfAssesment?: Date;
  comprehensiveAssessment?: boolean;
  other?: string;
  generalInfor?: GeneralInforModel;
  chiefComplaint?: ChiefComplaintModel;
  currentServices?: CurrentServicesModel;
  religiousCulturalLanguagePref?: ReligiousCulturalModel;
  leisureActivity: LeisureActivityModel[];
  identifiedStrengths?: string;
  identifiedNeeds?: string;
  currentLivingSituation?: currentLivingSituation;
  currentAddressDuration?: string;
  frequentMoves?: boolean;
  strengths?: string;
  needs?: string;
  houseHoldMember: HouseHoldMemberModel[];
  immediateFamilyOutside?: {
    nonReported?: boolean;
    explanation?: string;
  },
  feelingUnsafe?: {
    nonReported?: boolean;
    explanation?: string;
  },
  incomeSource?: {
    employed?: boolean;
    where?: string;
    SSDI?: boolean;
    unemployed?: boolean;
    yes?: boolean;
    explanation?: string;
  },
  transportSource?: {
    hasaCar?: boolean;
    publicTransport?: boolean;
  },
  adultDevelopmentalAbnormalities?: string,
  wnl?: boolean,
  noReportHistoryOfAbuse?: boolean,
  historyofAbuse?: HistoryofAbuseModel,
  pSAServiceHistory: PSAServiceHistoryModel[],
  noPSASserviceHistoryReported?: boolean,
  historyofPsychiatricDiagnoses: HistoryofPsychiatricDiagnosesModel[],
  noHistrotypsyDiagnosesReported?: boolean,
  historyofSymptoms?: HistoryofSymptomsModel,
  currentModications: CurrentMedicationsModel[],
  noCurrentMedicationsReported?: boolean,
  medicalHistory: MedicalHistoryModel,
  healthNotes?: HealthNotesModel,
  legalHistory?: LegalHistoryModel,
  substanceAbuse?: SubstanceInfoModel,
  asamDimensions?: AsamDimensionsModel,
  schoolName?: string,
  highestEducation?: string,
  employmentVocational?: EmploymentVocationalModel,
  rank?: string,
  yearsServed?: string,
  reasonforDischarge?: string,
  serviceConnectedDisability?: {
    yesOrNo?: boolean;
    explaination?: string;
  },
  independentLiving?: IndependentLivingModel;
  mentalStatusExam?: MentalStatusExamModel;
  suicideRiskPotential?: SuicideRiskPotentialModel;
  summaryofNeeds?: SummaryofNeedSchemaModel;
  recoveryHistoryandEnvironment?: RecoveryHistoryandEnvironmentModel;
  mortivationEngageInServices?: mortivationEngageInServices;
  currentImpairments?: CurrentImpairmentssModel;
  psychiatricSymptom?: psychiatricSymptomModel;
  summeryOfFindings?: SummeryOfFindingModel;
  eligibilityRecommendations?: string,
  treatmentRecommendations?: string,
  recommendationNotes?: string,
  saftyPlanNotNeeded?: boolean,
  clientSaftyPlanCompleted?: boolean,
  clientSaftyPlan?: ClientSaftyPlanModel;
  signatureDetails?: SignatureDetailsModel;
  gad7anxiety?: GAD7AnxietyModel;
  aceScore?:AceScoreModel;
  findingAceScore?: number;
  patientHealthQuestionaire?: PatientHealthQuestionaireModel;
  recoveryAssesmentScale?: RecoveryAssesmentScaleModel
  therapistSignature?: any;
  clientSignature?:any;
  updatedAt?: Date;
}

export interface ClinicalAssetmentFormOne {
  stepCount?: number;
  _id?: string,
  clientId?: string,
  assesmentHeader?: AssesmentHeaderModel;
  lengthOfAssestment?: string;
  dateOfAssesment?: Date;
  comprehensiveAssessment?: boolean;
  other?: string;
  generalInfor?: GeneralInforModel;
  chiefComplaint?: ChiefComplaintModel;
}