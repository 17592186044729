import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Input, Row, Table } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { CommonService } from "src/services/CommonService";
import { CurrentMedicationsModel, HistoryofPsychiatricDiagnosesModel, PSAServiceHistoryModel } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { DiagnosisCode } from "src/models/Insurance";

const FormThree = (props: any) => {
    const { formId, assesmenData, getAssesmentByClientId, customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        wnl: false,
        noPSASserviceHistoryReported: false,
        noHistrotypsyDiagnosesReported: false,
        noReportHistoryOfAbuse: false,
        noCurrentMedicationsReported: false,
        medicalHistory: { medicalHistoryTableSchema: [] },
        pSAServiceHistory: [{}],
    });
    const [edit, setEdit] = useState<boolean>(true)

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                leisureActivity: [],
                houseHoldMember: [],
                medicalHistory: { medicalHistoryTableSchema: [] },
                adultDevelopmentalAbnormalities: assesmenData.adultDevelopmentalAbnormalities || "",
                historyofAbuse: assesmenData.historyofAbuse || [],
                wnl: assesmenData.wnl,
                noReportHistoryOfAbuse: assesmenData.noReportHistoryOfAbuse || false,
                noPSASserviceHistoryReported: assesmenData.noPSASserviceHistoryReported || false,
                noHistrotypsyDiagnosesReported: assesmenData.noHistrotypsyDiagnosesReported || false,
                noCurrentMedicationsReported: assesmenData.noCurrentMedicationsReported || false,
                pSAServiceHistory: (assesmenData.pSAServiceHistory || []).map((servicesHistory: PSAServiceHistoryModel) => ({
                    type: servicesHistory.type || '',
                    providerName: servicesHistory.providerName || '',
                    datesofService: servicesHistory.datesofService || '',
                    outcome: servicesHistory.outcome || '',
                })),
                historyofPsychiatricDiagnoses: (assesmenData.historyofPsychiatricDiagnoses || []).map((diagnose: HistoryofPsychiatricDiagnosesModel) => ({
                    diagnosis: diagnose.diagnosis || '',
                    providerName: diagnose.providerName || ''
                })),
                historyofSymptoms: assesmenData.historyofSymptoms,
                currentModications: (assesmenData.currentModications || []).map((medication: CurrentMedicationsModel) => ({
                    Name: medication.Name || '',
                    Dosage: medication.Dosage || '',
                    Prescriber: medication.Prescriber || '',
                    Effects: medication.Effects || '',
                }))
            };
            setFormData(updateAssesment);
        }
    }, [customActiveTab, assesmenData]);

    const addServiceHistoryRow = () => {
        setFormData({
            ...formData,
            pSAServiceHistory: [...formData.pSAServiceHistory, {}]
        });
    };

    const addRowtoMadication = () => {
        setFormData({
            ...formData,
            currentModications: [...formData.currentModications, {}]
        });
    };

    const addpsyDiagnosesRow = () => {
        setFormData({
            ...formData,
            historyofPsychiatricDiagnoses: [...formData.historyofPsychiatricDiagnoses, {}]
        });
    };

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            stepCount: 3,
            _id: formId,
            leisureActivity: [],
            houseHoldMember: [],
            medicalHistory: { medicalHistoryTableSchema: [] },
            wnl: formData.wnl,
            adultDevelopmentalAbnormalities: formData.adultDevelopmentalAbnormalities,
            historyofAbuse: formData.historyofAbuse,
            noReportHistoryOfAbuse: formData.noReportHistoryOfAbuse,
            noPSASserviceHistoryReported: formData.noPSASserviceHistoryReported,
            noHistrotypsyDiagnosesReported: formData.noHistrotypsyDiagnosesReported,
            noCurrentMedicationsReported: formData.noCurrentMedicationsReported,
            pSAServiceHistory: formData.pSAServiceHistory.map(servicesHistory => ({
                type: servicesHistory.type,
                providerName: servicesHistory.providerName,
                datesofService: servicesHistory.datesofService,
                outcome: servicesHistory.outcome,
            })),
            historyofPsychiatricDiagnoses: formData.historyofPsychiatricDiagnoses.map(diagnose => ({
                diagnosis: diagnose.diagnosis,
                providerName: diagnose.providerName
            })),
            historyofSymptoms: formData.historyofSymptoms,
            currentModications: formData.currentModications.map(medication => ({
                Name: medication.Name,
                Dosage: medication.Dosage,
                Prescriber: medication.Prescriber,
                Effects: medication.Effects,
            }))
        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });

                getAssesmentByClientId()
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };
    useEffect(() => {
        const GetEditvalue = localStorage.getItem("isEdit");
        if (GetEditvalue !== null) {
            const isEdit = GetEditvalue === "true";
            setEdit(isEdit);
        }
    }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}

                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Developmental Milestones</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Col>
                                <Card className="bg-light-gray" style={{ height: "200px", marginBottom: "0.5rem" }}>
                                    <div style={{ margin: "0.2rem" }}>
                                        <h6 style={{ fontWeight: "500", display: "inline" }}>
                                            If consumer is an adult note any developmental abnormalities in box below or check here if WNL
                                            <input style={{ fontSize: "larger", marginLeft: "0.5rem", marginRight: "0.5rem", marginTop: "0.1rem" }}
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={formData.wnl}
                                                onClick={e => { setFormData({ ...formData, wnl: !formData.wnl }) }}
                                            />
                                            :
                                        </h6>

                                    </div>
                                    <div className="editor-area ck-content"
                                        style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                                        <CKEditor
                                            editor={Editor}
                                            id="floatingTextarea"
                                            disabled={!edit}
                                            data={formData?.adultDevelopmentalAbnormalities}
                                            onChange={(event: any, editor: any) => {
                                                const data = editor.getData();
                                                setFormData({ ...formData, adultDevelopmentalAbnormalities: data });
                                            }}
                                        />
                                    </div>
                                    {/* <textarea className="form-control" id="floatingTextarea" style={{ height: "100%", border: "none", backgroundColor: "unset", }}
                                        onChange={e => setFormData({ ...formData, adultDevelopmentalAbnormalities: e.target.value })}
                                    /> */}
                                </Card>
                            </Col>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex' }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '10%' }}>History of Abuse / Neglect / Trauma / Stressors</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                        disabled={!edit}
                                        checked={formData.noReportHistoryOfAbuse}
                                        onClick={e => setFormData(prevState => ({ ...prevState, noReportHistoryOfAbuse: !formData.noReportHistoryOfAbuse }))}
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Physical Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="physicalAbuse"
                                            checked={formData.historyofAbuse?.physicalAbuse?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, physicalAbuse: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="physicalAbuse"
                                                checked={formData.historyofAbuse?.physicalAbuse?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, physicalAbuse: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            disabled={formData.historyofAbuse?.physicalAbuse?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.physicalAbuse?.explanation || ''}
                                                            type="text"
                                                            onChange={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, physicalAbuse: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Sexual Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto  d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="sexualAbuse"
                                            checked={formData.historyofAbuse?.sexualAbuse?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, sexualAbuse: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="sexualAbuse"
                                                checked={formData.historyofAbuse?.sexualAbuse?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, sexualAbuse: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            disabled={formData.historyofAbuse?.sexualAbuse?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.sexualAbuse?.explanation || ''}
                                                            type="text"
                                                            onChange={e => setFormData({
                                                                ...formData, historyofAbuse: {
                                                                    ...formData.historyofAbuse,
                                                                    sexualAbuse: { explanation: e.target.value, nonReported: false }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Emotional Abuse:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input
                                            style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="emotionalAbuse"
                                            checked={formData.historyofAbuse?.emotionalAbuse?.nonReported === true}
                                            onClick={e => setFormData({
                                                ...formData,
                                                historyofAbuse: {
                                                    ...formData.historyofAbuse,
                                                    emotionalAbuse: { nonReported: true }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="emotionalAbuse"
                                                checked={formData.historyofAbuse?.emotionalAbuse?.nonReported === false}
                                                onClick={e => setFormData({
                                                    ...formData,
                                                    historyofAbuse: {
                                                        ...formData.historyofAbuse,
                                                        emotionalAbuse: { nonReported: false }
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.historyofAbuse?.emotionalAbuse?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.emotionalAbuse?.explanation || ''}
                                                            onChange={e => setFormData({
                                                                ...formData, historyofAbuse: {
                                                                    ...formData.historyofAbuse,
                                                                    emotionalAbuse: { explanation: e.target.value, nonReported: false }
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of Neglect:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="historyofNeglect"
                                            checked={formData.historyofAbuse?.historyofNeglect?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, historyofNeglect: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="historyofNeglect"
                                                checked={formData.historyofAbuse?.historyofNeglect?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, historyofNeglect: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.historyofAbuse?.historyofNeglect?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.historyofNeglect?.explanation || ''}
                                                            onChange={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, historyofNeglect: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={2}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Other trauma:</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="otherTrauma"
                                            checked={formData.historyofAbuse?.otherTrauma?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, otherTrauma: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="otherTrauma"
                                                checked={formData.historyofAbuse?.otherTrauma?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, otherTrauma: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.historyofAbuse?.otherTrauma?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.otherTrauma?.explanation || ''}
                                                            onChange={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, otherTrauma: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is there current CPS, DSS, or APS involvement?</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="currentInvolvementCPSDSSAPS"
                                            checked={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.nonReported === true}
                                            onClick={e => setFormData({
                                                ...formData, historyofAbuse: {
                                                    ...formData.historyofAbuse, currentInvolvementCPSDSSAPS: { nonReported: true }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={5}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="currentInvolvementCPSDSSAPS"
                                                checked={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.nonReported === false}
                                                onClick={e => setFormData({
                                                    ...formData, historyofAbuse: {
                                                        ...formData.historyofAbuse, currentInvolvementCPSDSSAPS: { nonReported: false }
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.currentInvolvementCPSDSSAPS?.explanation || ''}
                                                            onChange={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, currentInvolvementCPSDSSAPS: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={4}>
                                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is there history of CPS, DSS, or APS involvement?</h6>
                                </Col>
                                <Col xl={3}>
                                    <div className="col-auto d-flex">
                                        <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="historyInvolvementCPSDSSAPS"
                                            checked={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.nonReported === true}
                                            onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, historyInvolvementCPSDSSAPS: { nonReported: true } } })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None reported</label>
                                    </div>
                                </Col>
                                <Col xl={5}>
                                    <div className="row">
                                        <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                            <input
                                                style={{ fontSize: "larger" }}
                                                type="radio"
                                                className="form-check-input"
                                                name="historyInvolvementCPSDSSAPS"
                                                checked={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.nonReported === false}
                                                onClick={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, historyInvolvementCPSDSSAPS: { nonReported: false } } })}
                                            />
                                        </div>
                                        <div className="col">
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>Yes; Explain :</h6>
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            disabled={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.nonReported !== false || !edit}
                                                            value={formData.historyofAbuse?.historyInvolvementCPSDSSAPS?.explanation || ''}
                                                            onChange={e => setFormData({ ...formData, historyofAbuse: { ...formData.historyofAbuse, historyInvolvementCPSDSSAPS: { explanation: e.target.value, nonReported: false } } })}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex' }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '7%' }}>Psychiatric and Substance Abuse Services / History</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                        checked={formData.noPSASserviceHistoryReported}
                                        disabled={!edit}
                                        onClick={e => setFormData(prevState => ({ ...prevState, noPSASserviceHistoryReported: !formData.noPSASserviceHistoryReported }))}
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ minWidth: "600px" }}>
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "25%" }}>Type</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Provider Name</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Dates of Service (years)</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Outcome / Compliance</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.pSAServiceHistory?.map((servicesHistory, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={servicesHistory.type || ""}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.pSAServiceHistory || [])];
                                                                    updatedServicesHistory[index].type = e.target.value;
                                                                    setFormData({ ...formData, pSAServiceHistory: updatedServicesHistory });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={servicesHistory.providerName || ""}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.pSAServiceHistory || [])];
                                                                    updatedServicesHistory[index].providerName = e.target.value;
                                                                    setFormData({ ...formData, pSAServiceHistory: updatedServicesHistory });
                                                                }}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="number"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={servicesHistory.datesofService || ""}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.pSAServiceHistory || [])];
                                                                    updatedServicesHistory[index].datesofService = parseInt(e.target.value);
                                                                    setFormData({ ...formData, pSAServiceHistory: updatedServicesHistory });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                id="name"
                                                                readOnly={!edit}
                                                                value={servicesHistory.outcome || ""}
                                                                onChange={e => {
                                                                    const updatedServicesHistory = [...(formData.pSAServiceHistory || [])];
                                                                    updatedServicesHistory[index].outcome = e.target.value;
                                                                    setFormData({ ...formData, pSAServiceHistory: updatedServicesHistory });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                    <div className="py-3 d-flex justify-content-start">
                                        {edit &&
                                            <Button color="primary" onClick={addServiceHistoryRow}>Add More Details</Button>
                                        }
                                    </div>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex' }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '15%' }}>History of Psychiatric Diagnoses</h5>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" style={{ fontSize: "larger" }}
                                        checked={formData.noHistrotypsyDiagnosesReported}
                                        disabled={!edit}
                                        onClick={e => setFormData(prevState => ({ ...prevState, noHistrotypsyDiagnosesReported: !formData.noHistrotypsyDiagnosesReported }))}
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if no history reported
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ minWidth: "600px" }}>
                                    <thead>
                                        <tr>
                                            <td className="fw-500" style={{ width: "75%" }}>Diagnosis/Description</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Provider Name</td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.historyofPsychiatricDiagnoses?.map((diagnose, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                value={diagnose.diagnosis || ""}
                                                                readOnly={!edit}
                                                                onChange={e => {
                                                                    const updatedActivities = [...(formData.historyofPsychiatricDiagnoses || [])];
                                                                    updatedActivities[index].diagnosis = e.target.value;
                                                                    setFormData({ ...formData, historyofPsychiatricDiagnoses: updatedActivities });
                                                                }}
                                                                id="diagnonis" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col-auto">
                                                            <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                                type="text"
                                                                readOnly={!edit}
                                                                value={diagnose.providerName || ""}
                                                                id="providerName"
                                                                onChange={e => {
                                                                    const updatedActivities = [...(formData.historyofPsychiatricDiagnoses || [])];
                                                                    updatedActivities[index].providerName = e.target.value;
                                                                    setFormData({ ...formData, historyofPsychiatricDiagnoses: updatedActivities });
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                    <div className="py-3 d-flex justify-content-start">
                                        {edit &&
                                            <Button color="primary" onClick={addpsyDiagnosesRow}>Add More Details</Button>
                                        }
                                    </div>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>
                                History of Symptoms / Current Symptoms
                                <span style={{ fontSize: "0.8em" }}>
                                    (H = by history; C = current)
                                </span>
                            </h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ textAlign: "left", minWidth: "920px" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.impulsiveness || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, impulsiveness: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Impulsiveness
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.irritability || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, irritability: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Irritability
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.changeinHealthStatus || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, changeinHealthStatus: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Change in health status
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.InvoluntaryCommitment || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, InvoluntaryCommitment: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Involuntary commitment
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.angerManagement || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, angerManagement: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Anger management
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.legalInvolvement || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, legalInvolvement: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Legal involvement
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.appetiteDisturbance || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, appetiteDisturbance: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Appetite disturbance
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.impairedJudgment || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, impairedJudgment: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Impaired judgment
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.substanceAbuse || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, substanceAbuse: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Substance Abuse
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.truancy || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, truancy: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Truancy
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.sleepDisturbance || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, sleepDisturbance: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Sleep disturbance
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.traumaticBrainInjury || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, traumaticBrainInjury: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Traumatic Brain Injury
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.behaviorProblems || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, behaviorProblems: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Behavior problems
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.missedWork || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, missedWork: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Missed work
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.delusions || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, delusions: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Delusions
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.memoryLoss || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, memoryLoss: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Profound memory loss
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="name"
                                                        readOnly={!edit}
                                                        value={formData.historyofSymptoms?.moodSwings || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, moodSwings: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: "20%", paddingLeft: "0.2rem" }}>
                                                <div className="col-auto">
                                                    Mood swings
                                                </div>
                                            </td>
                                            <td style={{ width: "5%" }}>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        readOnly={!edit}
                                                        id="name"
                                                        value={formData.historyofSymptoms?.otherHC || ""}
                                                        onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, otherHC: e.target.value } })}
                                                    />
                                                </div>
                                            </td>
                                            <td colSpan={5} style={{ width: "70%", paddingLeft: "0.2rem" }}>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        Other :
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            readOnly={!edit}
                                                            id="name"
                                                            value={formData.historyofSymptoms?.other || ""}
                                                            onChange={e => setFormData({ ...formData, historyofSymptoms: { ...formData.historyofSymptoms, other: e.target.value } })}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>Current Medications</h5>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        style={{ fontSize: "larger" }}
                                        disabled={!edit}
                                        checked={formData.noCurrentMedicationsReported}
                                        onClick={e => setFormData(prevState => ({ ...prevState, noCurrentMedicationsReported: !formData.noCurrentMedicationsReported }))}
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem" }}>
                                        Check if none
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm m-1" style={{ minWidth: "600px" }}>
                                    <thead>
                                        <tr>
                                            <td className="fw-500" style={{ width: "25%" }}>Name</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Dosage/Frequency</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Prescriber</td>
                                            <td className="fw-500" style={{ width: "25%" }}>Effects</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.currentModications?.map((medication, index) => (
                                            <><tr key={index}>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            value={medication.Name || ""}
                                                            readOnly={!edit}
                                                            onChange={e => {
                                                                const updatedActivities = [...(formData.currentModications || [])];
                                                                updatedActivities[index].Name = e.target.value;
                                                                setFormData({ ...formData, currentModications: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={medication.Dosage || ""}
                                                            onChange={e => {
                                                                const updatedActivities = [...(formData.currentModications || [])];
                                                                updatedActivities[index].Dosage = e.target.value;
                                                                setFormData({ ...formData, currentModications: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={medication.Prescriber || ""}
                                                            onChange={e => {
                                                                const updatedActivities = [...(formData.currentModications || [])];
                                                                updatedActivities[index].Prescriber = e.target.value;
                                                                setFormData({ ...formData, currentModications: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-auto">
                                                        <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                            type="text"
                                                            id="name"
                                                            readOnly={!edit}
                                                            value={medication.Effects || ""}
                                                            onChange={e => {
                                                                const updatedActivities = [...(formData.currentModications || [])];
                                                                updatedActivities[index].Effects = e.target.value;
                                                                setFormData({ ...formData, currentModications: updatedActivities });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>


                                            </>
                                        ))}
                                    </tbody>

                                    <div className="py-3 d-flex justify-content-start">
                                        {edit &&
                                            <Button color="primary" onClick={addRowtoMadication}>Add More Details</Button>
                                        }
                                    </div>

                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                                paddingBottom: "7.5px !important"
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&
                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 3 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );

};

export default FormThree;